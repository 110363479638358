import tw from 'twin.macro';
import NavBar from './global/NavBar';
import Footer from './global/Footer';

export default function Layout({ children, isGallery, hasPadding }) {
  return (
    <>
      <div tw="text-black transition-colors duration-1000 bg-white dark:text-white dark:bg-black">
        {!isGallery && <NavBar />}
        <div
          css={[
            tw`min-h-screen`,
            hasPadding &&
              tw`flex flex-col max-w-screen-xl px-4 md:px-6 lg:px-20 mx-auto`,
          ]}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
}
