import 'twin.macro';
import { useTheme } from 'next-themes';
import { MoonIcon } from '@heroicons/react/solid';

export default function ToggleDarkMode() {
  const { theme, setTheme } = useTheme();

  return (
    <button
      aria-label="Toggle Dark Mode"
      type="button"
      tw="p-1 outline-none"
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      <MoonIcon tw="h-5 w-5 text-coolGray-100" />
    </button>
  );
}
