import 'twin.macro';
import Link from 'next/link';
import Image from 'next/image';

import ToggleDarkMode from './ToggleDarkMode';
import config from '../../utils/config';

export default function Footer() {
  return (
    <footer tw="border-t border-blueGray-100 dark:border-blueGray-900 ">
      <div tw="bg-black">
        <div tw="container flex flex-col items-center px-5 py-6 mx-auto lg:px-20 sm:flex-row">
          <a
            href="https://www.galllery.co/"
            tw="mt-1 cursor-pointer text-lg font-semibold rounded-lg focus:outline-none "
          >
            <div tw="inline-flex items-center">
              {/* <div tw="w-2 h-2 p-2 mr-2 rounded-full bg-lightBlue-500" /> */}
              <Image
                src="/images/logo.svg"
                alt="Gallery Logo"
                title="Gallery Logo"
                width={30}
                height={30}
                quality={80}
              />
              <h1 tw="ml-2 text-lg font-bold text-white transition duration-500 ease-in-out transform hover:text-gray-600 ">
                {config.siteName}.co
              </h1>
            </div>
          </a>
          <span tw="text-sm text-white px-4 invisible md:visible">|</span>
          <Link href="/terms">
            <a tw="cursor-pointer text-sm font-semibold rounded-lg focus:outline-none ">
              <div tw="inline-flex items-center">
                <h1 tw="text-sm text-white transition duration-500 ease-in-out transform hover:text-gray-600 ">
                  Terms
                </h1>
              </div>
            </a>
          </Link>
          <span tw="text-sm text-white px-4 invisible md:visible">|</span>
          <Link href="/privacy">
            <a tw="cursor-pointer text-lg font-semibold rounded-lg focus:outline-none ">
              <div tw="inline-flex items-center">
                <h1 tw="text-sm text-white transition duration-500 ease-in-out transform hover:text-gray-600 ">
                  Privacy
                </h1>
              </div>
            </a>
          </Link>
          <span tw="text-lg text-white px-4 invisible md:visible">|</span>
          <ToggleDarkMode />
          <span tw="inline-flex justify-center mt-4 sm:ml-auto sm:mt-0 sm:justify-start invisible md:visible">
            <a
              href="https://twitter.com/thepacman80"
              tw="cursor-pointer ml-3 text-white"
            >
              <svg
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                tw="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
