import tw, { styled } from 'twin.macro';
import { useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';

import config from '../../utils/config';

const NavLink = styled.a(({ isButton }) => [
  tw`cursor-pointer px-4 py-2 md:mt-0 md:ml-4 transition duration-500 ease-in-out transform rounded-lg font-medium mt-2`,
  isButton
    ? tw`text-white bg-black hover:bg-gray-800 dark:border`
    : tw`text-base text-black bg-transparent dark:text-blueGray-300 hover:text-blueGray-900 focus:text-blueGray-900 hover:bg-blueGray-200 focus:bg-blueGray-200 focus:outline-none`,
]);

const indexSections = [
  {
    label: 'Features',
    href: '#features',
  },
];

const mainNavigation = [
  // {
  //   label: 'Blog',
  //   href: '/blog',
  // },
  // {
  //   label: 'About',
  //   href: '/about',
  // },
  {
    label: 'Contact',
    href: '/contact',
  },
  {
    label: 'My Account',
    href: '/auth/account',
    isButton: true,
  },
];

export default function NavBar() {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const items =
    router.route === '/'
      ? [...indexSections, ...mainNavigation]
      : mainNavigation;

  const toggleOpen = useCallback(() => {
    setOpen((status) => !status);
  }, []);

  return (
    <div tw="fixed z-50 w-full text-black bg-white dark:text-white dark:bg-black">
      <div tw="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-20">
        <div tw="flex flex-row items-center justify-between p-4">
          <Link href="/">
            <a tw="cursor-pointer text-lg font-semibold rounded-lg focus:outline-none">
              <div tw="inline-flex items-center">
                <Image
                  src="/images/logo.svg"
                  alt="Gallery Logo"
                  title="Gallery Logo"
                  width={30}
                  height={30}
                  quality={100}
                />
                <h1 tw="ml-2 text-lg font-bold text-black transition duration-500 ease-in-out transform dark:text-white  hover:text-gray-300">
                  {config.siteName}
                </h1>
              </div>
            </a>
          </Link>
          <button
            tw="rounded-lg md:hidden focus:outline-none"
            onClick={toggleOpen}
            onBlur={toggleOpen}
            type="button"
          >
            <svg fill="currentColor" viewBox="0 0 20 20" tw="w-6 h-6">
              {!open ? (
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </button>
        </div>
        <nav
          css={[
            tw`flex-col flex-grow hidden pb-4 md:pb-0 md:flex md:justify-end md:flex-row`,
            open ? tw`flex` : tw`hidden`,
          ]}
        >
          {items.map((el) => {
            if (el.href) {
              return (
                <Link key={el.label} href={el.href}>
                  <NavLink isButton={el.isButton}>{el.label}</NavLink>
                </Link>
              );
            }

            return (
              <NavLink
                isButton={el.isButton}
                key={el.label}
                href="#"
                onClick={el.onClick}
              >
                {el.label}
              </NavLink>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
